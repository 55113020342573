<template>
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col xxl-3">
    <div class="card" style="height: 25em; max-height: 25em;">
      <div class="card-body text-center">
        <h4>Submission Status</h4>
        <hr />
        <ChartPie :dataLabel="dcpPie.labels" :dataSeries="dcpPie.series" />
      </div>
    </div>
  </div>
</template>

<script>
import ChartPie from "@/view/content/widgets/dashboard/ChartPie.vue";
export default {
  props: ["dcpPie"],
  data() {
    return {}
  },
  components: {
    ChartPie
  }
};
</script>

<style lang="scss" scoped></style>
