<template>
  <div>
    <FilterProject @code="getCode" />
    <div class="row mb-3">
      <DocumentControllProgress :codeProject="code" @dcpPie="updateDcpPie" />
      <SubmissionStatus :dcpPie="dcpPie" />
      <GeneralInformation :codeProject="code" />
    </div>
    <div class="row mb-3">
      <DocumentProgress :codeProject="code" />
    </div>
    <div class="row mb-3">
      <DetailInformation :codeProject="code" />
      <AllSubmission
        :codeProject="code"
        @updateSubmissionData="updateSubmissionData"
      />
    </div>
    <div class="row mb-3">
      <SubmissionProgress
        :codeProject="code"
        :submissionData="submissionData"
      />
    </div>
    <div class="row mb-3">
      <SubmissionTable
        :submissionTable="submissionData.submissionTable"
        :codeProject="code"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import FilterProject from "./Components/FilterProject";
import DocumentControllProgress from "./Charts/DocumentControllprogress";
import SubmissionStatus from "./Charts/SubmissionStatus";
import GeneralInformation from "./Charts/GeneralInformation";
import AllSubmission from "./Charts/AllSubmission";
import DetailInformation from "./Charts/DetailInformation";
import SubmissionTable from "./Charts/SubmissionTable";
import SubmissionProgress from "./Charts/SubmissionProgress";
import DocumentProgress from "./Charts/DocumentProgress";
export default {
  name: "DashboardProject",
  data() {
    return {
      asyncData: [],
      isDataLoading: false,
      code: "",
      submissionData: {
        submissionSeries: [],
        submissionCategory: [],
        submissionTable: []
      },
      dcpPie: {
        series: [],
        labels: []
      }
    };
  },
  async created() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard Project" }
    ]);
  },
  computed: {
    ...mapGetters(["currentLoadingDashboardProject"]),
    loading() {
      return this.currentLoadingDashboardProject;
    }
  },
  components: {
    FilterProject,
    DocumentControllProgress,
    DocumentProgress,
    SubmissionStatus,
    GeneralInformation,
    DetailInformation,
    AllSubmission,
    SubmissionTable,
    SubmissionProgress
  },
  methods: {
    getCode(value) {
      this.code = value;
      this.isDataLoading = true;
    },
    updateDcpPie(value) {
      this.dcpPie = value;
    },
    updateSubmissionData(value) {
      this.submissionData = value;
    }
  }
};
</script>
