<template>
  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col xxl-6">
    <div class="card" style="height: 25em; max-height: 25em;">
      <div class="card-body text-center">
        <h4>General Information</h4>
        <hr />
        <div class="table-responsive">
          <b-table
            sticky-header
            :items="generalInformation"
            head-variant="dark"
          ></b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_DASHBOARD_GENERAL_INFO } from "@/core/services/store/dashboard-project.module";
export default {
  props: ["codeProject"],
  data() {
    return {
      generalInformation: [
        {
          date: "",
          submitPercent: "",
          notYetPercent: "",
          submitAmount: "",
          notYetAmount: "",
          total: ""
        }
      ],
    };
  },
  computed: {
    ...mapGetters(["currentDashboardGeneralInfo"])
  },
  methods: {
    setGeneralInfo(data) {
      this.generalInformation = [];
      data.map(target =>
        this.generalInformation.push({
          date: target.week,
          submitPercent:
            target.submited === null ? 0 : target.submited.percentage,
          notYetPercent:
            target.not_yet === null ? 0 : target.not_yet.percentage,
          submitAmount: target.submited === null ? 0 : target.submited.count,
          notYetAmount: target.not_yet === null ? 0 : target.not_yet.count,
          total: target.total === null ? 0 : target.total.count
        })
      );
    },
    getGeneralInformation() {
      this.$store
        .dispatch(GET_DASHBOARD_GENERAL_INFO, this.codeProject)
        .then(() => this.setGeneralInfo(this.currentDashboardGeneralInfo));
    }
  },
  watch: {
    codeProject: function() {
      this.getGeneralInformation();
    }
  },
};
</script>

<style lang="scss" scoped></style>
