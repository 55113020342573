<template>
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col xxl-3">
    <div class="card" style="height: 25em; max-height: 25em;">
      <div class="card-body text-center">
        <h4>Document Controll Progress</h4>
        <hr />
        <div class="table-responsive">
          <b-table
            sticky-header
            :items="documentControllProgress"
            head-variant="dark"
          ></b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_DASHBOARD_DCP } from "@/core/services/store/dashboard-project.module";
export default {
  props: ["codeProject"],
  data() {
    return {
      documentControllProgress: [
        {
          planning: "",
          persentase: "",
          amount: ""
        }
      ],
      dcpPie: {
        series: [],
        labels: []
      }
    };
  },
  computed: {
    ...mapGetters(["currentDashboardDcp"])
  },
  watch: {
    codeProject: function(newVal) {
      this.getDcp(newVal);
    }
  },
  methods: {
    setDcp(data) {
      this.documentControllProgress = [];
      this.dcpPie.series = [];
      this.dcpPie.labels = [];
      data.map(target => {
        this.documentControllProgress.push({
          planning: target.status_type,
          persentase: target.percentage,
          amount: target.count
        });

        this.dcpPie.labels.push(target.status_type);
        this.dcpPie.series.push(target.count);
      });
      this.$emit("dcpPie", this.dcpPie);
    },
    getDcp() {
      this.$store
        .dispatch(GET_DASHBOARD_DCP, this.codeProject)
        .then(() => this.setDcp(this.currentDashboardDcp));
    }
  }
};
</script>

<style lang="scss" scoped></style>
