import { render, staticRenderFns } from "./AllSubmission.vue?vue&type=template&id=9e4d2312&scoped=true&"
import script from "./AllSubmission.vue?vue&type=script&lang=js&"
export * from "./AllSubmission.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e4d2312",
  null
  
)

export default component.exports