<template>
  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
    <div class="card" style="min-height: 30em;">
      <div class="card-body text-center">
        <h4>Detail Information</h4>
        <hr />
        <div class="table-responsive">
          <b-table
            sticky-header
            :items="detailInformation"
            head-variant="dark"
            style="max-height: 25em;"
          ></b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_DASHBOARD_DETAIL_INFO } from "@/core/services/store/dashboard-project.module";
export default {
  props: ["codeProject"],
  data() {
    return {
      detailInformation: [
        {
          scope: "",
          design_count: "",
          not_yet: "",
          progress: "",
          docon: "",
          designer: "",
          checker: ""
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["currentDashboardDetailInfo"])
  },
  methods: {
    setDetailInfo(data) {
      this.detailInformation = [];
      data.map(target =>
        this.detailInformation.push({
          scope: target.scope === null ? "" : target.scope.name,
          design_count: target.count,
          not_yet: target.blank,
          progress: target.percentage + "%",
          docon: target.docon,
          designer: target.design,
          checker: target.checker
        })
      );
    },
    getDetailInfo() {
      this.$store
        .dispatch(GET_DASHBOARD_DETAIL_INFO, this.codeProject)
        .then(() => this.setDetailInfo(this.currentDashboardDetailInfo));
    }
  },
  watch: {
    codeProject: function() {
      this.getDetailInfo();
    }
  }
};
</script>

<style lang="scss" scoped></style>
