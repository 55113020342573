<template>
  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
    <div class="card" style="min-height: 30em;">
      <div class="card-body text-center">
        <h4>All Submission</h4>
        <hr />
        <div class="table-responsive">
          <b-table-simple
            hover
            sticky-header
            small
            caption-top
            responsive
            style="maxHeight: 25em;"
          >
            <b-thead head-variant="dark">
              <b-tr>
                <b-th colspan="3" class="text-center"></b-th>
                <b-th colspan="3" class="text-center">Real</b-th>
              </b-tr>
              <b-tr>
                <b-th>Weekly Report</b-th>
                <b-th>Cut Off</b-th>
                <b-th>Plan</b-th>
                <b-th>Final</b-th>
                <b-th>On Progress</b-th>
                <b-th>Count</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(item, i) in allSubmition" :key="i">
                <b-td>{{ item.week_of_month }}</b-td>
                <b-td>{{
                  moment(item.cut_off_date).isValid()
                    ? moment(item.cut_off_date).format("DD-MM-YYYY")
                    : ""
                }}</b-td>
                <b-td>{{ item.total_plan }}</b-td>
                <b-td>{{ item.total_final }}</b-td>
                <b-td>{{ item.total_on_progress }}</b-td>
                <b-td>{{ item.total_on_progress_and_final }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_DASHBOARD_SUBMISSION_ALL } from "@/core/services/store/dashboard-project.module";
import { mapGetters } from "vuex";
export default {
  props: ["codeProject"],
  data() {
    return {
      allSubmition: [],
      submissionTable: [],
      submissionCategory: [],
      submissionSeries: [],
    };
  },
  computed: {
    ...mapGetters(["currentDashboardSubmissionAll"]),
  },
  methods: {
    setSubmissionAll(data) {
      // console.log("DATAX", data);
      this.allSubmition = [];
      this.submissionTable = [];
      this.submissionCategory = [];
      this.submissionSeries = [];
      data.map((target) => {
        this.allSubmition.push({
          id: target.id,
          week_of_month: target.week_of_month,
          total_on_progress_and_final: target.total_on_progress_and_final,
          total_plan: target.total_plan,
          total_final: target.total_final,
          total_on_progress: target.total_on_progress,
          cut_off_date: target.cut_off_date,
        });

        for (let i = 0; i < 4; i++) {
          if (i === 0) {
            this.submissionTable.push({
              plan: "Plan",
              year: target.week_of_month,
              type: "Percent",
              count: target.percentage_plan,
              cut_off_date: target.cut_off_date,
            });
          } else if (i === 1) {
            this.submissionTable.push({
              plan: "Plan",
              year: target.week_of_month,
              type: "Count",
              count: target.total_plan,
              cut_off_date: target.cut_off_date,
            });
          } else if (i === 2) {
            this.submissionTable.push({
              plan: "Real",
              year: target.week_of_month,
              type: "Count",
              count: target.total_final,
              cut_off_date: target.cut_off_date,
            });
          } else if (i === 3) {
            this.submissionTable.push({
              plan: "Real",
              year: target.week_of_month,
              type: "Percent",
              count: target.percentage_final,
              cut_off_date: target.cut_off_date,
            });
          }
        }

        let check = [];
        if (check.length === 0) {
          this.submissionCategory.push(target.week_of_month);
        }
      });

      let rencana = [];
      let realita = [];
      data.map((a) => {
        rencana.push(a.percentage_plan);
      });

      data.map((a) => {
        realita.push(a.percentage_final);
      });

      this.submissionSeries.push({
        name: "Rencana",
        data: rencana,
      });

      this.submissionSeries.push({
        name: "Realita",
        data: realita,
      });
      var SubmissionData = {
        submissionSeries: this.submissionSeries,
        submissionCategory: this.submissionCategory,
        submissionTable: this.submissionTable,
      };
      this.$emit("updateSubmissionData", SubmissionData);
      // console.log("SubmissionData", SubmissionData);
    },
    getSubmissionAll() {
      this.$store
        .dispatch(GET_DASHBOARD_SUBMISSION_ALL, this.codeProject)
        .then(() => this.setSubmissionAll(this.currentDashboardSubmissionAll));
    },
  },
  watch: {
    codeProject: function() {
      this.getSubmissionAll();
    },
  },
};
</script>

<style lang="scss" scoped></style>
