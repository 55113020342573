<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body text-center">
        <div>
          <h4>Document Progress</h4>
          <hr />
          <div class="table-responsive">
            <b-table-simple hover sticky-header small caption-top responsive>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th>Weekly Report</b-th>
                  <b-th>A</b-th>
                  <b-th>RET</b-th>
                  <b-th>B</b-th>
                  <b-th>C</b-th>
                  <b-th>ABD</b-th>
                  <b-th>TOTAL</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(data, i) in currentDashboardDocumentProgress" :key="i">
                  <b-td>{{ data.week_of_month }}</b-td>
                  <b-td>{{ data.A }}</b-td>
                  <b-td>{{ data.RET }}</b-td>
                  <b-td>{{ data.B }}</b-td>
                  <b-td>{{ data.C }}</b-td>
                  <b-td>{{ data.ABD }}</b-td>
                  <b-td>{{ data.total }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_DASHBOARD_DOCUMENT_PROGRESS } from "@/core/services/store/dashboard-project.module";
import { mapGetters } from "vuex";
export default {
  props: ["codeProject"],
  data() {
    return {
      datas: []
    };
  },
  computed: {
    ...mapGetters(["currentDashboardDocumentProgress"])
  },
  mounted() {
    console.log("documentProgress", this.currentDashboardDocumentProgress);
  },
  methods: {
    getAllDatas() {
      this.$store.dispatch(GET_DASHBOARD_DOCUMENT_PROGRESS, this.codeProject);
    }
  },
  watch: {
    codeProject: function() {
      this.getAllDatas();
    }
  }
};
</script>

<style lang="scss" scoped></style>
