<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body text-center">
        <h4>Submission Progress</h4>
        <hr />
        <ChartLine
          :category="this.submissionCategory"
          :series="this.submissionSeries"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChartLine from "@/view/content/widgets/dashboard/ChartLine.vue";
export default {
  props: ["submissionData"],
  data() {
    return {
      submissionSeries: [],
      submissionCategory: []
    };
  },
  components: {
    ChartLine
  },
  watch: {
    submissionData(value) {
      this.submissionSeries = value.submissionSeries;
      this.submissionCategory = value.submissionCategory;
    }
  }
};
</script>

<style lang="scss" scoped></style>
