<template>
  <div class="col-12">
    <div class="card" style="height: 40em">
      <div class="card-body text-center">
        <h4>Submission Pivot Table</h4>
        <hr />
        <pivot
          ref="pivot_table"
          :data="submissionTable"
          v-model="fields"
          :tableHeight="250"
          :reducer="reducer"
          :showSettings="true"
          class="pivotTable"
        >
          <template slot="value" slot-scope="{ value }">
            {{ value | number }}
          </template>
          <template slot="typeHeader" slot-scope="{ value }">
            {{ value | capitalize }}
          </template>
        </pivot>
      </div>
    </div>
  </div>
</template>

<script>
import { Pivot } from "vue-pivot-table-plus";
export default {
  props: ["submissionTable"],
  data() {
    return {
      reducer: (sum, item) => sum + item.count,
      data: this.data !== undefined ? this.data.sort() : this.data,
      defaultShowSettings: false,
      fields: {
        availableFields: [],
        rowFields: [
          {
            getter: (item) => item.plan,
            label: "Plan",
          },
          {
            getter: (item) => item.type,
            label: "type",
            headerSlotName: "typeHeader",
          },
        ],
        colFields: [
          {
            getter: (item) => {
              return item.cut_off_date;
            },
            label: "cut_off_date",
          },
        ],
        fieldsOrder: {},
      },
    };
  },
  components: {
    Pivot,
  },
  computed: {
    currentDataSubmission() {
      // console.log("DATA", this.data);
      return this.data;
    },
  },
  mounted() {
    var getPivotchild = document
      .querySelector(".pivotTable")
      .querySelectorAll(".row");
    var rowNumber = 0;
    getPivotchild.forEach((x) => {
      if (rowNumber >= 2) {
        x.querySelector(".left-col").classList.add("d-none");
        x.querySelector("[data-toggle=dropdown]").classList.add("d-none");
      } else {
        x.classList.add("d-none");
      }
      rowNumber++;
    });
  },
  methods: {
    getDataSubmissionFinal(listData, param) {
      // console.log("getDataSubmissionFinal", listData);
      return listData
        .filter((c) => c.year === param)
        .map((c) => c.percentage_final);
    },
    getDataSubmissionPlan(listData, param) {
      // console.log("getDataSubmissionPlan", listData);
      return listData
        .filter((c) => c.year === param)
        .map((c) => c.percentage_plan);
    },
  },
  filters: {
    number: function(value) {
      return value.toLocaleString();
    },
    capitalize: function(value) {
      return value.replace(/\b\w/g, (l) => l.toUpperCase());
    },
  },
  watch: {
    submissionTable() {
      var checkPivot = setInterval(() => {
        var getBtnDraggable = document.querySelectorAll(".btn-draggable");
        if (getBtnDraggable.length > 0) {
          getBtnDraggable[0].click();
          clearInterval(checkPivot);
        }
      }, 500);
    },
    currentDataSubmission(listData) {
      this.isDataLoading = true;
      setTimeout(() => {
        this.data = [...listData];
        this.asyncData = [...listData];
        this.isDataLoading = false;
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
.pivottable {
  max-height: 20em;
  table {
    overflow: auto;
  }
  table thead th,
  .table thead td {
    font-weight: 600;
    font-size: 1rem;
    border-bottom-width: 1px;
    padding-left: 2em;
    padding-right: 2em;
  }
}
</style>
