<template>
  <!-- filter column -->
  <div class="row mb-3">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <b-row class="my-1 d-flex align-items-center">
            <b-col sm="3">
              <label>Project Code :</label>
            </b-col>
            <b-col sm="9">
              <b-form-select
                v-model="filter.code_project"
                :options="formattedProjectOptions"
                size="sm"
                class="mt-3"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row class="my-1 d-flex align-items-center mt-2">
            <b-col sm="3">
              <label>Project Name :</label>
            </b-col>
            <b-col sm="9">
              <input
                type="text"
                disabled
                class="form-control"
                size="sm"
                v-model="filter.project_name"
              />
            </b-col>
          </b-row>
          <div class="row">
            <div class="col-12">
              <div class="text-right">
                <button class="btn btn-primary" @click="onShowData">
                  Show
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end filter column -->
</template>

<script>
import { arrayMoreThanOne } from "@/core/helper/array-validation.helper";
import { mapGetters } from "vuex";
import { GET_ALL_PROJECT_DETAIL } from "@/core/services/store/project.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "FilterProject",
  props: ["CodeProject"],
  mounted() {
    const jobdeskArray = ["koor","director","evp","vp","manager","ppc", "design", "interface","drafter","planner","enginer","admin"];
    ApiService.setHeader();
    ApiService.query("counter/project/list",{
      params: {
        jobdesks: jobdeskArray
      }
    }).then((response) => {
      this.jumlahProyekList = response.data.data;
      this.jumlahProyekLoading = false;
    });
  },
  data() {
    return {
      filter: {
        code_project: null,
        project_name: "",
      },
      options: [
        { value: null, text: "Please select an option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
        { value: { C: "3PO" }, text: "This is an option with object value" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      jumlahProyekList: [],
    };
  },
  filters: {
    number: function(value) {
      return value.toLocaleString();
    },
    capitalize: function(value) {
      return value.replace(/\b\w/g, (l) => l.toUpperCase());
    },
  },
  async created() {
    await this.$store.dispatch(GET_ALL_PROJECT_DETAIL, {
      jobdesk: "design",
      params: ["koor","director","evp","vp","manager","ppc", "design", "interface","drafter","planner","enginer","admin"]
    });
  },
  computed: {
    currentProject() {
      return this.filter.code_project;
    },
    ...mapGetters(["currentProjectDetails"]),
    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.jumlahProyekList)) {
        // console.log("ZXC", this.jumlahProyekList);
        projects = this.jumlahProyekList.map((project) => {
          return {
            value: project.code,
            text: project.code + "-" + project.nickname,
          };
        });
      }

      return [{ value: null, text: "Choose Project" }, ...projects];
    },
  },
  watch: {
    currentProject(value) {
      if (!value) {
        Object.assign(this.filter, {
          code_project: null,
          project_name: null,
        });
        return;
      }

      const project = this.currentProjectDetails.find((x) => x.code === value);
      // const project = this.jumlahProyekList.find((x) => x.code === value);
      Object.assign(this.filter, {
        code_project: project?.code,
        project_name: project?.name,
        ...project,
      });
    },
  },
  methods: {
    onShowData() {
      console.log(this.filter.code_project);
      this.$emit("code", this.filter.code_project);
    },
  },
};
</script>

<style lang="scss" scoped></style>
