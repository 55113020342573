<template>
  <div id="chart">
    <apexchart
      type="pie"
      width="100%"
      height="auto"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  name: "ChartPie",
  props: {
    dataSeries: {
      default: () => [],
      type: Array
    },
    dataLabel: {
      default: () => [],
      type: Array
    }
  },
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      series: [1],
      chartOptions: {
        chart: {
          width: "auto",
          type: "pie"
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        labels: ["No Data"]
      }
    };
  },
  mounted() {
    // console.log(this.dataLabel);
  },
  computed: {
    currentLabels() {
      return this.dataLabel;
    }
  },

  watch: {
    currentLabels(value) {
      var newData = [];
      for (var d in this.dataSeries) {
        newData.push(parseFloat(this.dataSeries[d]));
      }
      
      this.series = newData;
      this.chartOptions = {
        labels: value
      };
    }
  }
};
</script>
